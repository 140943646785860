<template>
  <div class="report-details">
    <!-- 头部 -->
    <div class="information-haeder-box">
      <div class="information-haeder">
        <div>
          <div class="quan" @click="goback">
            <img src="../../assets/img/views/jiantou.png" alt />
          </div>
          <span class="xiangq">{{reportName}}</span>
        </div>

        <!-- <div>
          <el-button
            @click="upDown"
            :disabled="downLoad"
            type="primary"
            plain
            size="small"
          >{{downLoad?"正在导出...":"下载报告"}}</el-button>
        </div>-->
      </div>
    </div>
    <!-- 面包屑 -->
    <el-breadcrumb separator-class="  " class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/report/index' }">舆情报告</el-breadcrumb-item>
      <el-breadcrumb-item>
        <span class="breadcrumb-meaasge">/</span> 报告详情
      </el-breadcrumb-item>
    </el-breadcrumb>
    <!-- 主体部分 -->
    <!-- <div class="img-box">
      <div>
        <img src="../../assets/img/views/nodata.png" />
        <p style="color: #999999;font-size: 16px;">正在维护中...</p>
      </div>
    </div>-->
    <!-- !!!!! -->
    <div class="details-main" v-loading="loading" v-if="!loading">
      <div class="main-box">
        <div class="box-left" v-if="msg == '新模板详情数据'">
          <div v-if="content">
            <div class="left-title">
              <p class="title-title" v-html="content.title ? content.title : ' '"></p>
              <p class="title-time" v-html="content.time ? content.time : ' '"></p>
            </div>
            <div class="left-text">
              <h3 v-html="content.reviewTitle ? content.reviewTitle : ' '"></h3>
              <div v-html="content.review ? content.review : ''"></div>
            </div>
            <div class="left-text">
              <h3 v-html="content.mediaCoverageTitle ? content.mediaCoverageTitle : ''"></h3>
              <div v-if="content.mediaCoverage&&content.mediaCoverage.length!=0">
                <p v-for="(item,index) in content.mediaCoverage" :key="index" v-html="item"></p>
              </div>
            </div>
            <div class="left-text">
              <h3
                v-html="content.revelationsAndConcernsTitle ? content.revelationsAndConcernsTitle : ''"
              ></h3>
              <p
                v-if="content.revelationsAndConcerns"
                v-html="content.revelationsAndConcerns.negativeEventType ? content.revelationsAndConcerns.negativeEventType:'' "
              ></p>
              <div v-if="content.revelationsAndConcerns">
                <div
                  v-if="content.revelationsAndConcerns.eventInformation &&content.revelationsAndConcerns.eventInformation.length!=0 "
                >
                  <div
                    v-for="(ite,index) in content.revelationsAndConcerns.eventInformation"
                    :key="index"
                  >
                    <p v-html="ite.eventInformationString"></p>
                    <div v-for="(it,index) in ite.informationInfoList" :key="index">
                      <p v-html="index+1 +'.'+ ' ' +it.informationName"></p>
                      <p v-html="it.informationLink"></p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="left-text">
              <h3 v-html="content.conclusionTitle ? content.conclusionTitle : ''"></h3>
              <div v-if="content.conclusion && content.conclusion.length!=0">
                <p v-for="(x,index) in content.conclusion" :key="index" v-html="x"></p>
              </div>
            </div>
          </div>
          <div class="notData" v-else>
            <span>暂无报告</span>
          </div>
        </div>
        <!-- 日报 月报模板 -->
        <div class="box-left" v-if="msg=='旧模板详情数据'">
          <div class="box-left-title" v-if="content.title" v-html="content.title"></div>
          <div class="bottom-title">
            <p class="strongText" v-if="content.shortTitle" v-html="content.shortTitle"></p>
            <p class="strongText" v-if="content.reportTime" v-html="content.reportTime"></p>
          </div>
          <!-- 正面 -->
          <div
            style="margin-top:16px"
            v-if="content.frontSentimentDocList && content.frontSentimentDocList.length!=0"
          >
            <p
              class="strongText"
              v-html="content.frontSentimentDocTitle?content.frontSentimentDocTitle:'无'"
            ></p>
            <!-- 循环体 -->
            <div
              style="margin-bottom:16px"
              v-for="(item,index) in content.frontSentimentDocList"
              :key="index"
            >
              <div class="bottom-code">
                <div style="max-width:800px">
                  <p v-html="item.title?item.title:'无'"></p>
                  <p>
                    <span class="strongText">舆情性质:</span>
                    <span v-html="item.tonalState?item.tonalState:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">消息来源:</span>
                    <span v-html="item.source?item.source:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">作者:</span>
                    <span v-html="item.author?item.author:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">网址链接:</span>
                    <span
                      style="display:inline-block;width:80%;white-space:normal;overflow:hidden"
                    >{{item.website?item.website:"无"}}</span>
                  </p>
                </div>
                <div class="codeImg">
                  <!-- <img :src="'data:img/png;base64,'+item.qrCodeUrl" alt /> -->
                  <img :src="item.qrCodeUrl" alt />
                </div>
              </div>
              <div>
                <span>
                  <span class="strongText">内容摘要:</span>
                </span>
                <span v-html="item.abstracts?item.abstracts:'无'"></span>
              </div>
            </div>
          </div>
          <!-- 中 -->
          <div
            style="margin-top:16px"
            v-if="content.neutralSentimentDocList && content.neutralSentimentDocList.length!=0"
          >
            <p
              class="strongText"
              v-html="content.neutralSentimentDocTitle?content.neutralSentimentDocTitle:'无'"
            ></p>
            <!-- 循环体 -->
            <div v-for="(item,index) in content.neutralSentimentDocList" :key="index">
              <div class="bottom-code">
                <div style="max-width:800px">
                  <p v-html="item.title?item.title:'无'"></p>
                  <p>
                    <span class="strongText">舆情性质:</span>
                    <span v-html="item.tonalState?item.tonalState:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">消息来源:</span>
                    <span v-html="item.source?item.source:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">作者:</span>
                    <span v-html="item.author?item.author:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">网址链接:</span>
                    <span
                      style="display:inline-block;width:80%;white-space:normal;overflow:hidden"
                    >{{item.website?item.website:"无"}}</span>
                  </p>
                </div>
                <div class="codeImg">
                  <!-- <img :src="'data:img/png;base64,'+item.qrCodeUrl" alt /> -->
                  <img :src="item.qrCodeUrl" alt />
                </div>
              </div>
              <div>
                <span>
                  <span class="strongText">内容摘要:</span>
                </span>
                <span v-html="item.abstracts?item.abstracts:'无'"></span>
              </div>
            </div>
          </div>
          <!--  -->
          <!-- 负 -->
          <div
            style="margin-top:16px"
            v-if="content.negativeSentimentDocList && content.negativeSentimentDocList.length!=0"
          >
            <p
              class="strongText"
              v-html="content.negativeSentimentDocTitle?content.negativeSentimentDocTitle:'无'"
            ></p>
            <!-- 循环体 -->
            <div v-for="(item,index) in content.negativeSentimentDocList" :key="index">
              <div class="bottom-code">
                <div style="max-width:800px">
                  <p v-html="item.title?item.title:'无'"></p>
                  <p>
                    <span class="strongText">舆情性质:</span>
                    <span v-html="item.tonalState?item.tonalState:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">消息来源:</span>
                    <span v-html="item.source?item.source:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">作者:</span>
                    <span v-html="item.author?item.author:'无'"></span>
                  </p>
                  <p>
                    <span class="strongText">网址链接:</span>
                    <span
                      style="display:inline-block;width:80%;white-space:normal;overflow:hidden"
                    >{{item.website?item.website:"无"}}</span>
                  </p>
                </div>
                <div class="codeImg">
                  <!-- <img :src="'data:img/png;base64,'+item.qrCodeUrl" alt /> -->
                  <img :src="item.qrCodeUrl" alt />
                </div>
              </div>
              <div>
                <span>
                  <span class="strongText">内容摘要:</span>
                </span>
                <span v-html="item.abstracts?item.abstracts:'无'"></span>
              </div>
            </div>
          </div>
          <!--  -->
        </div>
        <div class="box-right">
          <p class="box-right-top">导出记录</p>
          <ul>
            <li
              v-for="(item,index) in recordList"
              :key="index"
              :class="['box-right-li',(index+1)%2!=0?'box-right-li-bjc':'']"
            >
              <span>{{item.reportUser}}</span>
              <span>{{item.reword}}</span>
              <span>{{item.reportTime}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <!-- !!!!!!~ -->
    <!-- <div class="box-report" v-else>暂无报告</div> -->
  </div>
</template>

<script>
import { reportInfo, record } from "../../api/report/list";
export default {
  data() {
    return {
      // 传递过来的参数
      reportId: "",
      reportName: "",
      // reportType: "话题专项",
      reportType: "",
      type: 0,
      reportEndTime: "",
      reportStartTime: "",
      operator: "",
      //   内容
      content: {},
      loading: false,
      // 控制下载报告的按钮
      downLoad: false,
      // 首发媒体
      tableData: [
        {
          date: "2016-05-02",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1518 弄"
        },
        {
          date: "2016-05-04",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1517 弄"
        },
        {
          date: "2016-05-01",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1519 弄"
        },
        {
          date: "2016-05-03",
          name: "王小虎",
          address: "上海市普陀区金沙江路 1516 弄"
        }
      ],
      recordList: [], //导出记录
      msg: ""
    };
  },
  methods: {
    goback() {
      this.$router.push("/report/index");
    },
    async reportInfo() {
      this.loading = true;
      const res = await reportInfo({
        reportId: this.reportId,
        organizationName: this.$store.state.user.mechanism,
        reportType: this.reportType,
        type: this.type,
        reportEndTime: this.reportEndTime,
        reportStartTime: this.reportStartTime
      });
      if (res != undefined) {
        this.msg = res.data.msg;
        this.content = res.data.data;
      }
      this.loading = false;
    },
    // 下载导出
    upDown() {
      this.downLoad = true;
      let obj = {
        method: "post",
        url: "api/datastudy/report/downLoadReportStream",
        responseType: "blob",
        data: {}
      };
      obj.data = {
        reportId: this.reportId,
        organizationName: this.$store.state.user.mechanism
      };
      let time = this.$calcu.timeCycle(new Date(), "yyyy-MM-dd HH：mm");
      let textName =
        this.$store.state.user.mechanism +
        "舆情" +
        this.reportType +
        "-" +
        this.operator +
        time +
        ".docx";
      this.$parent.downloadFun(obj, textName);
      this.downLoad = false;
    },
    async record() {
      const res = await record({
        reportId: this.reportId
      });
      this.recordList = res.data.rows;
    }
  },
  created() {
    let params = JSON.parse(decodeURIComponent(this.$route.query.info));
    if (params) {
      this.reportId = Number(params.reportId);
      this.reportName = params.reportName;
      this.reportType = params.reportType;
      this.operator = params.operator;
      this.type = params.type;
      this.reportEndTime = params.reportEndTime;
      this.reportStartTime = params.reportStartTime;
    }
    this.reportInfo();
    this.record();
  }
};
</script>

<style scoped>
.details-main {
  padding: 0 60px 24px 60px;
  width: 100%;
}

.breadcrumb {
  margin-top: 77px;
  margin-left: 24px;
}

.main-box {
  display: flex;
  width: 100%;
}

.box-left {
  width: 70%;
  min-height: 713px;
  padding: 40px 80px 40px 80px;
  background: #ffffff;
  border-radius: 8px;
  margin-right: 24px;
}
.box-right {
  width: 29%;
  min-height: 713px;
  padding: 24px 16px 24px 24px;
  background: #ffffff;
  border-radius: 8px;
}
.left-title {
  border-bottom: 1px solid #e4e6ec;
  padding-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.notData {
  line-height: 356px;
  text-align: center;
}

.title-title {
  font-size: 30px;
  font-weight: bold;
  color: #333333;
}

.title-time {
  margin-top: 16px;
  font-size: 14px;
  color: #999999;
}

.left-text {
  font-size: 14px;
  color: #333333;
  margin-top: 32px;
  width: 100%;
  line-height: 26px;
  text-indent: 2em;
}

.box-report {
  text-align: center;
  width: 100%;
  line-height: 500px;
  height: 500px;
}

.information-haeder {
  height: 100%;
}

.xiangq {
  width: 1480px;
}

.quan {
  display: inline-block;
  vertical-align: top;
}
/* 话题专项样式 */
.reportType_title {
  text-align: center;
}
.reportType_time {
  margin-top: 16px;
  text-align: center;
}
.reportType_zy {
  text-indent: 32px;
  line-height: 40px;
}
/* 右边导出记录的样式 */
.box-right-top {
  font-size: 16px;
  font-weight: 600;
  color: #333333;
  margin-bottom: 16px;
}
.box-right-li {
  padding: 16px;
  border-radius: 4px;
  min-height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.box-right-li-bjc {
  background: #f4f7fd;
}
.box-right-li span {
  font-size: 14px;
  color: #333333;
}
.box-right-li span:nth-child(1) {
  max-width: 150px;
}
.box-left-title {
  font-size: 40px;
  text-align: center;
  color: red;
}
.bottom-title {
  margin-top: 16px;
  border-bottom: 1px solid red;
}
.bottom-code {
  display: flex;
  justify-content: space-between;
}
.codeImg {
  width: 100px;
  height: 100px;
}
.codeImg img {
  width: 100%;
  height: 100%;
}
.img-box {
  height: 500px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-box p {
  text-align: center;
}
.strongText {
  font-weight: 600;
}
</style>
